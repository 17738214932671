//NOTE: deprecated
<template>
  <div class="row export-page animated fadeIn">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header">
          <i class="fa fa-download"></i>
          Export
        </div>

        <div class="card-block">
          <h5 class="mb-1">{{ $t('statistics.exportStat') }}</h5>
          <div class="row">
            <div class="col-lg-6">
              <div class="mb-1">
                <strong>1. {{ $t('statistics.chooseCPEs') }}</strong>
                <a href="#" class="ml-h" @click.prevent="toggleAllCpes">
                  <span v-if="isAllCpesSelected">{{ $t('general.removeAll') }}</span>
                  <span v-else>{{ $t('general.selectAll') }}</span>
                </a>
                <a href="#" class="ml-q pull-right" @click.prevent="isWlanID = !isWlanID">
                  <span v-if="!isWlanID">{{ $t('general.showid') }}</span>
                  <span v-else>{{ $t('general.hideid') }}</span>
                </a>
              </div>
              <multiselect
                class="mb-1"
                v-model="commonSelectedCpes"
                :multiple="true"
                :options="cpesByModelList || []"
                groupLabel="model"
                groupValues="cpes"
                label="name"
                :disabled="cpesByModelList && !cpesByModelList.length"
                :customLabel="ssidWithID"
                :groupSelect="true"
                trackBy="id"
                :closeOnSelect="false"
                placeholder="Type to search"
              ></multiselect>
            </div>
            <div class="col-lg-3">
              <p>
                <strong>2. {{ $t('statistics.chooseDate') }}</strong>
              </p>
              <date-picker v-model="drPickerDateForExport" />
            </div>
            <div class="col-lg-3">
              <p>
                <strong>3. {{ $t('statistics.export') }}</strong>
              </p>
              <button
                :disabled="!commonSelectedCpes.length || isCpesExportLoading"
                class="btn btn-info btn-md"
                @click="
                  getCpesExport(
                    `cpes-mac-statistics ${moment(drPickerDateForExport.startDate).format(
                      ' D MMM YYYY, H:mm '
                    )} - ${moment(drPickerDateForExport.endDate).format(' D MMM YYYY, H:mm ')}`
                  )
                "
              >
                <i class="fa fa-download mr-h"></i>
                <span :class="{ invisible: isCpesExportLoading }">{{ $t('statistics.createAndDownload') }}</span>
                <span v-if="isCpesExportLoading" class="loader loader--mini"></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { Multiselect } from 'vue-multiselect';
import VueNotifications from 'vue-notifications';
import cpeService from '../../services/cpeService';
import DatePicker from '../../components/date-picker.vue';
import helpers from '../../helpers';

const MAX_LBS_LIMIT = 100000;

export default {
  name: 'Statexport',
  components: { Multiselect, DatePicker },
  data() {
    return {
      cpesList: [],
      commonSelectedCpes: [],
      isWlanID: false,
      drPickerDateForExport: {
        startDate: moment().subtract(24, 'hours').valueOf(),
        endDate: moment().valueOf()
      }
    };
  },
  computed: {
    cpesByModelList() {
      const { cpesList } = this.$store.state;
      if (cpesList) {
        const exportCpesList = [];
        for (const cpe of cpesList) {
          if (exportCpesList[cpe.model.name]) {
            exportCpesList[cpe.model.name].cpes.push({ name: cpe.name, id: cpe.id });
          } else {
            exportCpesList[cpe.model.name] = { cpes: [{ name: cpe.name, id: cpe.id }] };
          }
        }

        const newExportCpesList = [];
        const keys = Object.keys(exportCpesList);

        keys.forEach((key) => {
          const newObj = { ...exportCpesList[key] };
          newObj.model = `Model: ${key}`;
          newExportCpesList.push(newObj);
        });

        newExportCpesList.sort(helpers.compareModel);

        return newExportCpesList;
      }
    },
    isCpesExportLoading() {
      return this.$store.state.loadingCpesExport;
    },
    isAllCpesSelected() {
      if (!this.cpesList.length) {
        return false;
      }
      return this.cpesList.length === this.commonSelectedCpes.length;
    }
  },
  methods: {
    ssidWithID({ id, name }) {
      if (!name) {
        name = 'No name';
      }
      if (this.isWlanID && id) {
        return `${name}: ${id}`;
      }
      return `${name}`;
    },
    toggleAllCpes() {
      if (!this.isAllCpesSelected) {
        this.commonSelectedCpes = [];
        for (const model of this.cpesByModelList) {
          if (model.cpes.length) {
            this.commonSelectedCpes.push(...model.cpes);
          }
        }
      } else {
        this.commonSelectedCpes = [];
      }
    },
    getCpesExport(filename) {
      this.$store.commit('toggleLoadingCpesExport');

      const start = moment(this.drPickerDateForExport.startDate).unix();
      const stop = moment(this.drPickerDateForExport.endDate).unix();

      const ids = this.commonSelectedCpes.map((cpe) => cpe.id);

      Vue.axios
        .post(`${window.RESTAPI || ''}/api/report/lbs/probes`, {
          action: 'R',
          ids,
          page: {
            limit: MAX_LBS_LIMIT
          },
          timebounds: { start, stop }
        })
        .then(
          (response) => {
            if (response.status === 200) {
              const blob = new Blob([response.data], { type: 'text/csv' });

              const url = window.URL.createObjectURL(blob);

              const downloadLink = document.createElement('a');
              document.body.appendChild(downloadLink);
              downloadLink.style = 'display: none';

              downloadLink.href = url;
              downloadLink.download = filename;

              downloadLink.click();

              window.URL.revokeObjectURL(url);
              document.body.removeChild(downloadLink);

              this.$store.commit('toggleLoadingCpesExport');
            } else {
              VueNotifications.error({ message: response.data.description });
              this.$store.commit('toggleLoadingCpesExport');
            }
          },
          (err) => {
            VueNotifications.error({ message: err });
            this.error = err;
            this.$store.commit('toggleLoadingCpesExport');
          }
        );
    }
  },
  created() {
    this.$store.commit('setCpesList', false);
    // cpeService.getAllCompactCpes(this);
    cpeService.getAllCompactCpesWithDataRemaining(this);
  },
  filters: {
    moment(value) {
      if (value) {
        return moment(value * 1000).format(' D MMM YYYY, H:mm ');
      }
    },
    toFixed(val, precission) {
      if (val && precission) {
        return val.toFixed(precission);
      }
    }
  }
};
</script>

<style lang="scss">
.export-page {
  min-width: 900px;
}
</style>
